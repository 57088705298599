<script setup>
import { defineProps, computed, defineEmits} from 'vue';


const emit = defineEmits([
    'click',
    'select'
])

const props = defineProps({
    access: {
        type: String,
        required: true,
        validator(value) {
            return [
                'internal',
                'external'
            ].includes(value);
        }
    },

    disabled: {
        type: Boolean,
        default: true
    },

    open: {
        type: Boolean,
        default: false
    }
})

const accessRoleData = {
    internal: {
        icon: 'fa-user-pen',
        type: 'success'
    },
    external: {
        icon: 'fa-users',
        type: 'danger'
    }
}

const availableOptions = computed(() => {
    return Object.keys(accessRoleData).filter(key => props.access !== key)
})

const role = (role) => accessRoleData[role]

const handleDropdownClick = () => {
    // disabled for now
    return;
    emit('click')
}
const handleDropdownSelect = (selection) => {
    emit('select', selection)
}
</script>

<template>
    <div class="access-role-dropdown"
    :title="'User has ' + props.access + ' access'"
    :class="{'access-role-dropdown-disabled': props.disabled }">
        <div class="access-role-current" @click="handleDropdownClick" :class="role(props.access).type">
            <span class="access-icon small">
                <i class="fa-regular" :class="role(props.access).icon"></i>
            </span>
            <span class="access-role">{{ props.access }}</span>
            <!-- hidden for now -->
            <!-- <span class="access-icon" v-if="isDropdown">
                <i class="fa-solid" :class="'fa-caret-' + (props.open  ? 'up'  : 'down')"></i>
            </span> -->
        </div>
        <div class="access-role-options" v-if="props.open">
            <div class="access-role-option" 
            v-for="option in availableOptions"
            @click="handleDropdownSelect(option)"
            :class="role(option).type"
            :key="option">
                <span class="access-icon small">
                    <i class="fa-regular" :class="role(option).icon"></i>
                </span>
                <span class="access-role">{{ option }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.access-role-dropdown {
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 2px;
    border-radius: 2px;
    border-radius: 3px;
}

.access-role-dropdown .access-role-current {
    border-radius: 5px;
    padding: 1px 5px;
    display: flex;
    align-items: center;
}

.access-role-dropdown .access-role {
    text-transform: capitalize;
    margin: 0 3px;
}

.access-role-dropdown .success {
    background-color: #9CCDCD;
}

.access-role-dropdown .danger {
    background-color: #EFD0DB;
}

.access-role-dropdown .access-icon {
    margin: 0 2px;
}
.access-role-dropdown .access-icon.small {
    font-size: 11px;
}
</style>