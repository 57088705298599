<script setup>
import axios from 'axios';
import { ref, onMounted, nextTick } from 'vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { ToastProgrammatic as Toast } from 'buefy';
import SuperdocUserDropdown from './SuperdocUserDropdown.vue';
import UserAccessDropdown from './SuperdocUserAccessDropdown.vue';

const emit = defineEmits(['close', 'shared', 'removeCollaborator']);
const props = defineProps({
  superdocId: {
    type: String,
    required: true,
  },
  superdocTitle: {
    type: String,
    required: true,
  },
  collaborators: {
    type: Array,
    required: true,
  },
  agreementId: {
    type: String,
    required: false,
  },
  linkId: {
    type: String,
    required: false,
  },
  orgUserEmails: {
    type: Array,
    default: () => []
  }
});

const notifyPeople = ref(true);
const message = ref('');
const linkWasCopied = ref(false);

const getAccess = (i) => i % 2 ? 'internal' : 'external' // TEMPORARY
const generateCollaborators = (predicate) => props.collaborators?.filter(predicate).map((user, index) => ({
  ...user,
  access: {
    role: getAccess(index),
    dropdownOpen: false
  }
})) || [];

const initialCollaborators = ref(generateCollaborators((user) => !user.inheritedRole));
const currentCollaborators = ref(generateCollaborators((user) => !user.inheritedRole));
const inheritedCollaborators = ref(generateCollaborators((collaborator) => collaborator.immutableRole));
const isSubmitting = ref(false);

const invalidEmail = ref(false);
const errorMessage = ref('');


const initializeTippy = () => {
  tippy('[data-tippy-content]');
};

onMounted(() => {
  initializeTippy();
});

const showCopySuccess = () => {
  linkWasCopied.value = true;
  setTimeout(() => {
  linkWasCopied.value = false;
  }, 2000);
};

const isChangeRoleDisabled = (user) => {
  return inheritedCollaborators.value.some((collaborator) => collaborator.email === user.email);
};

const copyLink = () => {
  let url = `${window.location.origin}/document/${props.superdocId}`;
  if (props.agreementId && props.linkId) {
    url += `/agreement/${props.agreementId}/link/${props.linkId}`;
  }
  navigator.clipboard.writeText(url);
  showCopySuccess();
};

const handleShare = async () => {
  isSubmitting.value = true;
  const newEmails = new Set();
  const newCollaborators = currentCollaborators.value.filter((collaborator) => {
    const isNew = !initialCollaborators.value.some((initialCollaborator) => initialCollaborator.email === collaborator.email);
    if (isNew) newEmails.add(collaborator.email);
    return isNew;
  });

  const changedCollaborators = [];
  initialCollaborators.value.forEach((collaborator) => {
    const initialRole = collaborator.role;
    const currentRole = currentCollaborators.value.find((currentCollaborator) => currentCollaborator.email === collaborator.email).role;
    if (initialRole !== currentRole) {
      changedCollaborators.push({
        email: collaborator.email,
        name: collaborator.name,
        role: currentRole,
        immutableRole: collaborator.immutableRole,
        inheritedRole: collaborator.inheritedRole,
      });
    }
  });

  const allCollaborators = new Set([...newCollaborators, ...changedCollaborators]);
  try {
    const promises = [];
    allCollaborators.forEach((collaborator) => {
      const payload = {
        role: collaborator.role,
        user_email: collaborator.email,
        notify_users: notifyPeople.value,
        notification_message: notifyPeople.value ? message.value : null,
      };
      promises.push(axios.put(`/api/documents/${props.superdocId}/share`, payload))
    });
    emit('shared', currentCollaborators.value);
    await Promise.all(promises);
    emit('close');
  }
  catch (error) {
    console.error(error);
    Toast.open({
      message: `So sorry! We are unable to share the document at this time. Try again later or contact support@harbourshare.com.`,
      type: 'is-danger',
      position: 'is-top',
      duration: 4000,
    });
  }
  finally {
    isSubmitting.value = false;
  }
};

const handleNewRole = (user, role) => {
  user.role = role;
};

const removeUser = async (user) => {
  const userIsNew = !initialCollaborators.value.some((collaborator) => collaborator.email === user.email);
  currentCollaborators.value = currentCollaborators.value.filter((collaborator) => collaborator.email !== user.email);

  if (userIsNew) return;
  emit('removeCollaborator', user);

  initialCollaborators.value = initialCollaborators.value.filter((collaborator) => collaborator.email !== user.email);
  await axios.put(`/api/documents/${props.superdocId}/share`, {
    role: null,
    user_email: user.email,
  });
};

const isInheritedCollaborator = async (email) => {

  let inheritedCollaborator = inheritedCollaborators.value.find((collaborator) => collaborator.email === email);
  let inheritedCollaboratorName = inheritedCollaborator?.name || null;
  // this might be a new document or document was open for long time and the inherited collaborator was added
  if (!inheritedCollaborator) {
    const { data } = await axios.get(
      `/api/documents/${props.superdocId}/check-inherited-collaborator/${email}`
    );
    inheritedCollaborator = data?.has_inherited_permissions || false;
    inheritedCollaboratorName = data?.collaborator_name || null;
  }
  if (inheritedCollaborator) {
    inheritedCollaborators.value.push({
      email: email,
      name: inheritedCollaboratorName,
      role: 'editor',
      immutableRole: true,
      inheritedRole: true,
    });
    // find the collaborator in the currentCollaborators array and update the name, role and immutableRole
    const collaborator = currentCollaborators.value.find((collaborator) => collaborator.email === email);
    if (collaborator) {
      collaborator.name = inheritedCollaboratorName;
      collaborator.role = 'editor';
      collaborator.immutableRole = true;
      collaborator.inheritedRole = true;
    }
    nextTick(() => {
      initializeTippy();
    });
  }
};

const handleCollaboratorEntry = () => {
  const email = event.target.value;
  if (!email) return;

  const isValid = validateEmail(email);
  if (!isValid) return showError();

  const alreadyExists = currentCollaborators.value.some((collaborator) => collaborator.email === email);
  if (!alreadyExists) {
    currentCollaborators.value.push({
      email: event.target.value,
      name: null,
      role: 'editor',
      picture: null,
      immutableRole: false,
      inheritedRole: false,
    });
  }
  // Re-init tippy to show the tooltip on the new collaborator
  isInheritedCollaborator(email);
  event.target.value = '';
}

const showError = () => {
  invalidEmail.value = true;
  errorMessage.value = 'Please enter a valid email address';

  setTimeout(() => {
    resetError();
  }, 5000)
};

const resetError = () => {
  invalidEmail.value = false;
  errorMessage.value = '';
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

// which user has an open dropdown
const currentOpenDropdownUserEmail = ref(null);

const handleAccessDropdownClick = (user) => {
  const currentUserEmail = currentOpenDropdownUserEmail.value;
  currentOpenDropdownUserEmail.value = null;

  const newUserEmail = user.email;
  if (currentUserEmail === newUserEmail) return;
  currentOpenDropdownUserEmail.value = user.email;
}

const handleAccessDropdownSelect = (selection, user = null) => {
  handleAccessDropdownClick(user);
  // modify user perms here
  // udateUserAccess(selection, user)
}

const isUserAccessDropdownOpen = (user) => {
  return user.email === currentOpenDropdownUserEmail.value;
}

const getUserAccessRole = (user) => {
  const userInGroup = props.orgUserEmails.find(email => user.email === email);
  if (userInGroup) return 'internal';
  return 'external';
}
</script>

<template>
  <div class="superdoc-share-modal">

    <div class="superdoc-share-modal-content">
      <div class="superdoc-share-modal-inner">
        <div class="share-header">
          Share {{ superdocTitle }}
        </div>

        <div class="share-input">
          <i class="fal fa-user-plus input-user-icon"></i>
          <input
            type="text"
            placeholder="Add collaborator email"
            @keydown.enter.stop.prevent="handleCollaboratorEntry"
            @input="resetError"
            @blur="handleCollaboratorEntry" />
        </div>
        <div class="error-message">{{ errorMessage }}</div>

        <div class="people-with-access">
          <div class="people-with-access-header">
            <div>People with access</div>
          </div>

          <div class="users-container">
            <div class="user-row" v-for="user in currentCollaborators" :key="user.email">
              <div class="user-side">
                <div class="user-photo">
                  <img :src="user.picture" :alt="user.name" v-if="user.picture">
                  <div class="user-initial" v-else>{{ user.email[0]?.toUpperCase() }}</div>
                </div>
                <div class="user-details">
                  <div class="user-name-and-access">
                    <div class="user-name" v-if="user.name">{{ user.name }}</div>
                  </div>
                  <div class="user-email">{{ user.email }}</div>
                </div>
              </div>
              <div class="user-actions">
                <div class="user-access">
                  <userAccessDropdown
                  @click="handleAccessDropdownClick(user)"
                  @select="(selection) => handleAccessDropdownSelect(selection, user)"
                  :disabled="true"
                  :open="isUserAccessDropdownOpen(user)"
                  :access="getUserAccessRole(user)"/>
                  <div class="spacer">&nbsp;</div>
                </div>

                <span v-if="isChangeRoleDisabled(user)" data-tippy-content="User is an admin and role cannot be changed" tabindex="0">
                  <SuperdocUserDropdown :user="user" :disabled="true"  @set-role="handleNewRole(user, $event)" />
                </span>
                <SuperdocUserDropdown v-else :user="user" :disabled="false"  @set-role="handleNewRole(user, $event)" />
                <div class="user-delete" @click.stop.prevent="removeUser(user)">
                  <i class="fal fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="notification-section">
          <div class="notification-section-header">
            <input type="checkbox" v-model="notifyPeople" />
            <div class="notify-btn" @click="notifyPeople = !notifyPeople">Notify people</div>
          </div>

          <div class="notification-message">
            <textarea placeholder="Follow the link below to begin collaborating on the document." v-model="message"></textarea>
          </div>
        </div>
      </div>

      <div class="separator"></div>

      <div class="superdoc-share-modal-inner">
        <div class="share-buttons">
          <div>
            <button @click="copyLink" v-if="!linkWasCopied">
              <i class="fal fa-link"></i>Copy link
            </button>
            <div class="link-copied-confirmation" v-else>
              <i class="fal fa-check-square" style="margin-right: 5px;"></i>Link copied!
            </div>
          </div>
          <div>
            <button @click="emit('close')" v-if="currentCollaborators.length">Cancel</button>
            <button @click="emit('close')" v-else>Close</button>
            <button class="is-primary" @click="handleShare" :class="{ disabled: !currentCollaborators.length || isSubmitting }">
              <div v-if="!isSubmitting">Share</div>
              <div v-else>
                <i class="fal fa-spinner-third fa-spin" style="margin-right: 5px;"></i>Sharing
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.error-message {
  color: #BA2B4A;
  font-size: 13px;
  border-left: 2px solid #BA2B4A;
  padding-left: 10px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.superdoc-share-modal {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.superdoc-share-modal-content {
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: white;
}
.superdoc-share-modal-inner {
  padding: 0 30px;
  width: 600px;
}
.users-container {
  max-height: 400px;
  overflow-y: auto;
}
.people-with-access-header{
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.share-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.share-input {
  margin-bottom: 15px;
  position: relative;
}
.notification-section {
  margin-top: 40px;
}
.notification-section-header {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.notification-section-header input {
  margin-right: 10px;
}
.superdoc-share-modal .share-input input {
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 40px;
  padding-left: 40px;
  padding-right: 10px;
  transition: all 250ms ease;
  width: 100%;
}
.superdoc-share-modal .share-input input:focus,
.superdoc-share-modal textarea:focus  {
  border: 1px solid #1355FF;
  outline: none;
}
.input-user-icon {
  position: absolute;
  top: 14px;
  left: 15px;
  font-size: 12px;
}
.share-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
}
.link-copied-confirmation {
  font-size: 12px;
}
.superdoc-share-modal button {
  padding: 12px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dbdbdb;
  font-weight: 400;
  background-color: white;
  cursor: pointer;
  transition: all 250ms ease;
}
.superdoc-share-modal button:hover {
  background-color: #DBDBDB;
}
.superdoc-share-modal button:not(:first-child) {
  margin-left: 5px;
}
.superdoc-share-modal button i {
  margin-right: 5px;
}
.notification-message {
  margin: 20px 0;
}
.superdoc-share-modal textarea {
  outline: none;
  resize: none;
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px 15px;
  transition: all 250ms ease;
}
.separator {
  height: 1px;
  background-color: #dbdbdb;
  width: 100%;
  margin: 0;
  padding: 0;
}
.is-primary {
  background-color: #1355FF !important;
  color: white;
}
.is-primary:hover {
  background-color: #0133B7 !important;
  color: white;
}
.user-row {
  display: flex;
  border-top: 1px solid #dbdbdb;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.user-access {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}
.user-actions {
  display: flex;
}
.user-actions .user-delete {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: all 250ms ease;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-actions .user-delete:hover {
  background-color: #dbdbdb;
}
.user-side {
  position: relative;
  width: 70%;
  display: flex;
  align-items: center;
}
.user-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-right: 15px;
}
.user-photo img,
.user-photo .user-initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-initial {
  background-color: #dbdbdb;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.user-name-and-access {
  display: flex;
  gap: .5em;
  align-items: center;
}
.user-name {
  font-weight: 600;
  font-size: 13px;
}
.user-email {
  font-size: 13px;
}
</style>
